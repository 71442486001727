@import "./colors";
@import "./mixins";

.content {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-header {
    @include flex-center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 150px;
    background-image: url(../assets/images/header-banner-logo.svg), linear-gradient(93.63deg, #bd58cc -22.63%, #12529d 73.87%);
    background-repeat: no-repeat;
    background-position: 90% 50%;
    color: white;
    padding: 40px;
    row-gap: 16px;

    .text-row-small {
      @include flex-center;
      width: 100%;
      max-width: 1000px;
      box-shadow: none;
      font-size: 14px;
      gap: 8px;
      padding-left: 12px;
      .arrow-icon {
        height: 14px;
        width: 14px;
        margin: 0 0 0 0.5rem;
      }
    }

    .text-row {
      font-family: sans-serif;
      margin: 0;
      font-weight: 700;
      width: 100%;
      max-width: 1000px;
      box-shadow: none;
      font-size: 21px !important;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    .content {
      width: 100%;
      max-width: 1030px;
      padding: 40px 20px;
      flex: auto;
    }
  }
}
