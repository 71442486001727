@import "./colors";
@import "./mixins";

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-top: 2px solid $color-neutral-200;

  .footer-content {
    @include flex-center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
    max-width: 998px;

    .footer-content-col {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        cursor: pointer;
        color: $color-primary;
        font-size: 16px;
      }

      .social {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        row-gap: 13px;

        .social-icons {
          display: flex;
          justify-content: space-between;
          min-width: 170px;

          a {
            font-size: 13px;
            @include flex-center;
            box-shadow: 0 2px 6px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
            border-radius: 50%;
            padding: 3px;
            margin: 3px;
            color: $color-text;
            .social-icon {
              height: 20px;
              width: 20px;
            }
          }
        }

        p {
          font-size: 14px;

          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
