@import "./colors";
@import "./mixins";

.header {
  position: sticky;
  top: 0;
  background-color: $color-background;
  box-shadow: 0 2px 20px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  z-index: 300;

  .nav {
    @include flex-center;
    background-color: $color-background;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;

    .company-logo {
      @include flex-center;
      align-self: center;
      box-sizing: inherit;
      .image {
        display: inline-block;
        cursor: pointer;
        height: 35px;
        overflow-clip-margin: content-box;
        overflow: clip;
        box-sizing: inherit;
      }
    }

    .ul-items {
      @include flex-center;
      justify-content: center;
      list-style: none;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        @include flex-center;
        text-decoration: none;
        color: $color-neutral;
        margin: 0 0.5rem;
        font-size: 14px;
        cursor: pointer;
        font-family: sans-serif;
      }

      .user-profile-dropdown {
        @include flex-center;
        box-sizing: inherit;
        .profile-photo-container {
          overflow: hidden;
          position: relative;
          height: 36px;
          width: 36px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          .profile-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .username-container {
          @include flex-center;
          .username {
            @include flex-center;
            margin: 0 0 0 0.5rem;
            color: $color-primary;
          }
          .arrowdropdown-icon {
            color: $color-text;
          }
        }
      }
      .color-text {
        color: $color-text;
      }

      .lang {
        @include flex-center;
        margin: 0 1.5rem;
        .lang-icon {
          height: 16px;
          width: 16px;
          margin: 0 0.5rem;
        }
      }

      .help-icon {
        height: 16px;
        width: 16px;
        margin: 0 0 0 0.5rem;
        color: $color-neutral;
      }
    }
  }
}
