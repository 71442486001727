html,
body,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.App {
  display: flex;
  flex-direction: column;  
  flex: auto;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.5;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bec4c4;
  border-radius: 14px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
